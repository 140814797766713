import _ from 'lodash'
import S3 from '../../components/react-aws-s3'

class S3BucketUploader {
  async uploadAssetToS3Bucket(
    assetFolderName,
    authorId,
    file,
    updatedFileName,
    extension,
  ) {
    const config = {
      bucketName: `${process.env.REACT_APP_BUCKET_NAME}`,
      dirName: `assets/${assetFolderName}/${authorId}`,
      region: `${process.env.REACT_APP_REGION}`,
      accessKeyId: process.env.REACT_APP_API_KEY,
      secretAccessKey: process.env.REACT_APP_SECRET_KEY,
    }

    const ReactS3Client = new S3(config)
    const data = await ReactS3Client.uploadFile(
      file,
      `${updatedFileName}`,
      `${extension}`,
    )
    return data
  }
}

export default new S3BucketUploader()
