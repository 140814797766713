import _ from 'lodash'
import getBlobDuration from 'get-blob-duration'
import * as FileUtils from '../../utils/FileUtils'
import * as UploadServices from '../UploadServices'
import S3BucketUploader from './S3BucketUploader'
import { ONE_SECOND } from '../../constants/Timeline'

class LibraryAssetUploader {
  async saveAsLibraryAsset(
    fileBlob = null,
    fileUrl,
    assetFolderName,
    authorId,
    additionalSuffix = '',
    fileBlobs = {},
    filesUploaded = false,
    segmentVideo = false,
  ) {
    const fileName = FileUtils.getFilename(fileUrl) + additionalSuffix

    const extension = FileUtils.getFileExtension(fileUrl)
    const updatedFileName = FileUtils.replaceSpacesIntoUnderscores(fileName)

    const asset = {}
    /**
     * If the function receives a Blob raw data, then it is uploaded to the S3
     * If the function receives Blobs and the parmeter filesUploaded is set to false (that is a default value also), it uploads Blobs objects to the S3
     */
    if (fileBlob) {
      asset.blobDuration = await this.getDurationByBlob(fileBlob)
      asset.responseData = await S3BucketUploader.uploadAssetToS3Bucket(
        assetFolderName,
        authorId,
        fileBlob,
        updatedFileName,
        extension,
      )
    } else {
      if (!filesUploaded)
        asset.responseData = await S3BucketUploader.uploadAssetToS3Bucket(
          assetFolderName,
          authorId,
          Object.values(fileBlobs)[0],
          updatedFileName,
          extension,
        )
      else {
        asset.responseData = {
          location: fileUrl,
          key: fileUrl
            .replace('s3-eu-west-2', 's3.eu-west-2')
            .replace(process.env.REACT_APP_S3_URL, ''),
        }
      }
      const responseDataPromises = []

      Object.keys(fileBlobs).forEach((aspectSize) => {
        const splitFileName = `${fileUrl}_${aspectSize}${additionalSuffix}`

        const blob = fileBlobs[aspectSize]

        if (!asset.fileBlob) asset.fileBlob = blob
        if (!filesUploaded)
          responseDataPromises.push(
            S3BucketUploader.uploadAssetToS3Bucket(
              assetFolderName,
              authorId,
              blob,
              FileUtils.replaceSpacesIntoUnderscores(splitFileName),
              extension,
            ),
          )
      })

      asset.blobDuration = await this.getDurationByBlob(
        asset.fileBlob || fileUrl,
      )
      const allResponseData = []
      await Promise.all(responseDataPromises)
        .then((response) => allResponseData.push(response))
        .catch((error) => console.error(error))
    }

    asset.parameters = this.createLibraryFileParameters(
      authorId,
      updatedFileName,
      asset.responseData,
      assetFolderName,
      extension,
      asset.blobDuration,
    )

    if (filesUploaded)
      asset.parameters = {
        ...asset.parameters,
        isTrackingVideo: filesUploaded && asset.fileBlob,
      }

    const response = await UploadServices.libraryFilesUpload({
      ...asset.parameters,
      segmentVideo,
    })

    if (response.data.result === 'error') {
      throw new Error(response.data.result)
    }
    return response.data.message
  }

  async getDurationByBlob(fileBlob) {
    try {
      return await getBlobDuration(fileBlob)
    } catch (error) {
      if (!fileBlob?.type) {
        throw new Error('Invalid video')
      }
      if (fileBlob.type.includes('video') || fileBlob.type.includes('audio')) {
        return 0
      }
      return 5 * ONE_SECOND
    }
  }

  createLibraryFileParameters(
    authorId,
    updatedFileName,
    responseData,
    assetFolderName,
    extension,
    duration = 0,
  ) {
    return {
      userId: authorId,
      fileName: updatedFileName,
      fileUrl: responseData.location,
      fileKey: responseData.key,
      fileType: assetFolderName,
      extension,
      duration,
      isCropped: [false, false, false, false],
      selectedLayout: [false, false, false, false],
      croppingStarted: false,
      aspectSize: [
        { width: 0, height: 0, top: 0, left: 0 },
        { width: 0, height: 0, top: 0, left: 0 },
        { width: 0, height: 0, top: 0, left: 0 },
        { width: 0, height: 0, top: 0, left: 0 },
      ],
      streamSize: [
        { width: 0, height: 0 },
        { width: 0, height: 0 },
        { width: 0, height: 0 },
        { width: 0, height: 0 },
      ],
    }
  }
}

export default new LibraryAssetUploader()
